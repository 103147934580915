import 'slick-carousel';

$(function () {
    // top_banner_pcのカルーセル用jQuery
    $('.top-banner-pc-carousel').slick({
        appendDots: $('.dots_pc'),
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: '<button class="slide-arrow prev-arrow-pc"></button>',
        nextArrow: '<button class="slide-arrow next-arrow-pc"></button>'
    });

    // top_banner_spのカルーセル用jQuery
    $('.top-banner-sp-carousel').slick({
        appendDots: $('.dots_sp'),
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: '<button class="slide-arrow prev-arrow-sp"></button>',
        nextArrow: '<button class="slide-arrow next-arrow-sp"></button>'
    });
});
